import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useRVOKeyFactCardStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleBold: { fontWeight: "bold" },
    titleSerif: { fontFamily: "Playfair Display" },
    titleBlack: { color: theme.palette.primary.dark },
    card: {
      height: "100%",
    },

    backgroundWhite: { backgroundColor: theme.palette.background.paper },
    backgroundGold: {
      backgroundColor: theme.palette.warning.light,
      boxShadow: `0 0.375rem 0.75rem ${theme.palette.grey[200]}80`,
    },

    wrapperContent: {
      flexDirection: "column",
    },
    iconWrapper: {
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "50%",
      height: theme.spacing(2.75), // 44px
      width: theme.spacing(2.75), // 44px
      marginBottom: theme.spacing(1.875), // 30rem
    },
    iconGold: {
      backgroundColor: theme.palette.warning.light,
    },
    iconBlue: {
      backgroundColor: theme.palette.secondary.main,
    },
  })
);
