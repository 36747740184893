import { Box, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { clsx } from "clsx";
import { RVOTextImageComboProps } from "./RVOTextImageComboInterfaces";
import { useRVOTextImageComboStyles } from "./RVOTextImageComboStyles";

const RVOTextImageCombo = (props: RVOTextImageComboProps): JSX.Element => {
  const classes = useRVOTextImageComboStyles();
  const theme = useTheme();

  return (
    <Box className={clsx(classes.wrapper)}>
      <Box
        style={{
          display: "flex",
          flexDirection: props.imagePosition === "left" ? "row-reverse" : "row",
          gap: theme.spacing(4),
          justifyContent: "flex-start",
          width: "100%",
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: theme.spacing(1),
            width: useMediaQuery(theme.breakpoints.down("xs")) ? "100%" : "50%",
          }}
        >
          <Typography
            variant={props.headlineBig ? "h1" : "h2"}
            component="h3"
            className={clsx(
              classes.wrapper,
              props.textColor === "light"
                ? classes.textColorLight
                : classes.textColorDark
            )}
            style={{
              marginBottom: theme.spacing(1),
              fontFamily: props.serif ? "Playfair Display" : "Montserrat",
              fontWeight: "bold",
              textAlign: "left",
            }}
          >
            {props.headline}
          </Typography>

          {props.children}
        </Box>

        {useMediaQuery(theme.breakpoints.down("xs")) ? null : (
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "50%",
            }}
          >
            {props.image ? props.image : undefined}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default RVOTextImageCombo;
