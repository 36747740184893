import { Card, Grid, Typography } from "@material-ui/core";
import { RVOVerticalCardProps } from "./RVOVerticalCardInterfaces";
import { useRVOVerticalCardStyles } from "./RVOVerticalCardStyles";

const RVOVerticalCard = (props: RVOVerticalCardProps): JSX.Element => {
  const classes = useRVOVerticalCardStyles();

  return (
    <Card className={classes.card} elevation={0}>
      <Grid container={true} className={classes.gridContainer}>
        <Grid item={true} xs={12} sm={12} md={12} className={classes.image}>
          {props.image}
        </Grid>

        <Grid item={true} xs={12} sm={12} md={12} className={classes.textArea}>
          <Typography variant="h4" component="h4" className={classes.name}>
            {props.title}
          </Typography>

          <Typography
            variant="subtitle1"
            component="p"
            className={classes.position}
          >
            {props.subtitle}
          </Typography>

          <Typography variant="body1" className={classes.description}>
            {props.children}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
};

export default RVOVerticalCard;
