import { createStyles, makeStyles } from "@material-ui/core";
import { fontWeightBold } from "../../../layouts/theme";

export const useRVOVerticalCardStyles = makeStyles((theme) =>
  createStyles({
    card: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      height: "100%",
      width: "100%",
      padding: 0,
      backgroundColor: "transparent",
      boxShadow: "none",
    },
    gridContainer: {
      height: "100%",
    },
    image: {
      height: "auto",
      "& > img": {
        width: "100%",
        objectFit: "contain",
        height: "100%",
        maxHeight: "300px",
        padding: theme.spacing(2),
      },
    },
    textArea: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
    },
    name: {
      textAlign: "left",
      marginBottom: theme.spacing(0.5),
      fontWeight: fontWeightBold,
    },
    position: {
      textAlign: "left",
      marginBottom: theme.spacing(1),
      color: theme.palette.warning.dark,
    },
    description: {},
  })
);
