import { Box, Button, Grid, Typography, useTheme } from "@material-ui/core";
import {
  Https,
  LocationOn,
  Person,
  Save,
  Security,
  ThumbUp,
} from "@material-ui/icons";
import BusinessIcon from "@material-ui/icons/Business";
import SignIcon from "@material-ui/icons/Create";
import DnsIcon from "@material-ui/icons/Dns";
import LockIcon from "@material-ui/icons/Lock";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import RVOKeyFactCard from "../../02-molecules/RVOKeyFactCard/RVOKeyFactCard";
import RVOTextImageCombo from "../../02-molecules/RVOTextImageCombo/RVOTextImageComboSection";
import RVOVerticalCard from "../../02-molecules/RVOVerticalCard/RVOVerticalCard";
import {
  BackgroundColors,
  HeadlineComponent,
  HeadlineVariant,
} from "../../03-organisms/RVOFeaturedTextSection/RVOFeaturedTextInterfaces";
import RVOFeaturedTextSection from "../../03-organisms/RVOFeaturedTextSection/RVOFeaturedTextSection";

const RVOSecurityTemplate = (): JSX.Element => {
  const { t } = useTranslation();

  const theme = useTheme();

  return (
    <>
      <Helmet>
        <title>{t("security.headerSection.title")}</title>

        <meta
          name="description"
          content={t("security.headerSection.subtitle")}
        />
      </Helmet>

      <RVOFeaturedTextSection
        backgroundColored={BackgroundColors.BlueDark}
        headlineCentered={true}
        wide={true}
        pretextComponent={HeadlineComponent.H1}
        xxlHeadline={true}
        pretext={t("security.headerSection.pretitle")}
        headline={t("security.headerSection.title")}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            variant="body1"
            style={{
              maxWidth: theme.breakpoints.values.sm,
              textAlign: "center",
              margin: "auto",
              marginTop: theme.spacing(4),
              marginBottom: theme.spacing(6),
              color: theme.palette.warning.main,
            }}
          >
            {t("security.headerSection.subtitle")}
          </Typography>

          <LockIcon
            style={{ color: theme.palette.common.white, fontSize: "100px" }}
          />
        </Box>
      </RVOFeaturedTextSection>

      <RVOFeaturedTextSection
        pretext={t("security.sectionLocation.pretitle")}
        headlineCentered={true}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            gap: theme.spacing(6),
          }}
        >
          <RVOTextImageCombo
            serif={false}
            headlineBig={true}
            headline={t("security.sectionLocation.title")}
            image={
              <Security
                style={{ color: theme.palette.warning.dark, fontSize: "100px" }}
              />
            }
          />

          <Grid container={true} justifyContent="center" spacing={2}>
            <RVOKeyFactCard
              title={t("security.sectionLocation.keyfact1.title")}
              titleBold={true}
              titleSerif={true}
              variant="h4"
              description={t("security.sectionLocation.keyfact1.description")}
              icon={<Security />}
            />

            <RVOKeyFactCard
              title={t("security.sectionLocation.keyfact2.title")}
              titleBold={true}
              titleSerif={true}
              variant="h4"
              description={t("security.sectionLocation.keyfact2.description")}
              icon={<LocationOn />}
            />

            <RVOKeyFactCard
              title={t("security.sectionLocation.keyfact3.title")}
              titleBold={true}
              titleSerif={true}
              variant="h4"
              description={t("security.sectionLocation.keyfact3.description")}
              icon={<ThumbUp />}
            />
          </Grid>
        </Box>
      </RVOFeaturedTextSection>

      <RVOFeaturedTextSection
        pretext={t("security.audittrailSection.pretitle")}
        headlineCentered={true}
        backgroundColored={BackgroundColors.BlueLight}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            gap: theme.spacing(6),
          }}
        >
          <RVOTextImageCombo
            serif={false}
            headline={t("security.audittrailSection.keyfact1.title")}
            imagePosition="left"
            image={
              <SignIcon
                style={{ color: theme.palette.warning.dark, fontSize: "70px" }}
              />
            }
          >
            <Typography variant="body1">
              {t("security.audittrailSection.keyfact1.description")}
            </Typography>
          </RVOTextImageCombo>

          <RVOTextImageCombo
            serif={false}
            headline={t("security.audittrailSection.keyfact2.title")}
            image={
              <VerifiedUserIcon
                style={{ color: theme.palette.warning.dark, fontSize: "70px" }}
              />
            }
          >
            <Typography variant="body1">
              {t("security.audittrailSection.keyfact2.description")}
            </Typography>
          </RVOTextImageCombo>
        </Box>
      </RVOFeaturedTextSection>

      <RVOFeaturedTextSection
        pretext={t("security.sectionDatenschutz.pretitle")}
        headlineCentered={true}
        headline={t("security.sectionDatenschutz.title")}
        boldHeadline={true}
        headlineVariant={HeadlineVariant.H1}
        headlineComponent={HeadlineComponent.H3}
        noSerif={true}
      >
        <Grid container={true} justifyContent="center" spacing={3}>
          <Grid item={true} xs={12} md={6} spacing={3}>
            <RVOVerticalCard
              title={t("security.sectionDatenschutz.keyfact1.title")}
              image={
                <StarBorderIcon
                  style={{
                    color: theme.palette.warning.dark,
                    fontSize: "70px",
                    marginBottom: theme.spacing(1),
                  }}
                />
              }
            >
              {t("security.sectionDatenschutz.keyfact1.description")}

              <br />

              <br />

              <Button
                variant="outlined"
                href="mailto:support@resolvio.com?subject=Liste%20der%20Unterauftragnehmer&body=Liebes%20Resolvio-Team%2C%0A%0Aich%20habe%20Interesse%20an%20der%20Resolvio-App%20und%20w%C3%BCrde%20gerne%20erfahren%2C%20welche%20Unterauftragnehmer%20derzeit%20bei%20der%20Resolvio-App%20eingesetzt%20werden.%20Ihr%20Angebot%2C%20die%20aktuelle%20Liste%20der%20Unterauftragnehmer%20w%C3%BCrde%20ich%20daher%20gerne%20in%20Anspruch%20nehmen.%0A%0AFreundliche%20Gr%C3%BC%C3%9Fe%0A%0A"
              >
                {t("security.sectionDatenschutz.keyfact1.linkText")}
              </Button>
            </RVOVerticalCard>
          </Grid>

          <Grid item={true} xs={12} md={6} spacing={3}>
            <RVOVerticalCard
              title={t("security.sectionDatenschutz.keyfact2.title")}
              image={
                <DnsIcon
                  style={{
                    color: theme.palette.warning.dark,
                    fontSize: "70px",
                    marginBottom: theme.spacing(1),
                  }}
                />
              }
            >
              {t("security.sectionDatenschutz.keyfact2.description")}
            </RVOVerticalCard>
          </Grid>
        </Grid>
      </RVOFeaturedTextSection>

      <RVOFeaturedTextSection
        pretext={t("security.sectionLegal.pretitle")}
        headlineCentered={true}
        backgroundColored={BackgroundColors.BlueDark}
      >
        <RVOTextImageCombo
          serif={false}
          headline={t("security.sectionLegal.title")}
          textColor="light"
          image={
            <BusinessIcon
              style={{ color: theme.palette.warning.dark, fontSize: "70px" }}
            />
          }
        >
          <Typography
            variant="body1"
            style={{ color: theme.palette.common.white }}
          >
            {t("security.sectionLegal.description")}
          </Typography>
        </RVOTextImageCombo>
      </RVOFeaturedTextSection>

      <RVOFeaturedTextSection
        pretext={t("security.sectionDatensicherheit.pretitle")}
        headlineCentered={true}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            gap: theme.spacing(6),
          }}
        >
          <RVOTextImageCombo
            serif={false}
            headlineBig={true}
            headline={t("security.sectionDatensicherheit.title")}
            imagePosition="left"
            image={
              <Save
                style={{ color: theme.palette.warning.dark, fontSize: "100px" }}
              />
            }
          />

          <Grid container={true} justifyContent="center" spacing={2}>
            <RVOKeyFactCard
              title={t("security.sectionDatensicherheit.keyfact1.title")}
              titleBold={true}
              titleSerif={true}
              variant="h4"
              description={t(
                "security.sectionDatensicherheit.keyfact1.description"
              )}
              icon={<Save />}
            />

            <RVOKeyFactCard
              title={t("security.sectionDatensicherheit.keyfact2.title")}
              titleBold={true}
              titleSerif={true}
              variant="h4"
              description={t(
                "security.sectionDatensicherheit.keyfact2.description"
              )}
              icon={<Person />}
            />

            <RVOKeyFactCard
              title={t("security.sectionDatensicherheit.keyfact3.title")}
              titleBold={true}
              titleSerif={true}
              variant="h4"
              description={t(
                "security.sectionDatensicherheit.keyfact3.description"
              )}
              icon={<Https />}
            />
          </Grid>
        </Box>
      </RVOFeaturedTextSection>
    </>
  );
};

export default RVOSecurityTemplate;
