import { createStyles, makeStyles } from "@material-ui/core";

export const useRVOTextImageComboStyles = makeStyles((theme) =>
  createStyles({
    wrapper: {
      maxWidth: theme.breakpoints.only("md"),
    },

    textColorLight: { color: theme.palette.common.white },
    textColorDark: { color: theme.palette.primary.dark },
  })
);
