import { Card, Grid, Typography } from "@material-ui/core";

import { clsx } from "clsx";
import { SanitizeHTML } from "../../01-atoms/SanitizeHTML/SanitzeHTML";
import { RVOKeyFactCardProps } from "./RVOKeyFactCardInterfaces";
import { useRVOKeyFactCardStyles } from "./RVOKeyFactCardStyles";

const RVOKeyFactCard = (props: RVOKeyFactCardProps): JSX.Element => {
  const classes = useRVOKeyFactCardStyles();

  return (
    <Grid item={true} xs={12} md={6} lg={4}>
      <Card
        className={clsx(
          classes.card,
          props.bgColorGold ? classes.backgroundGold : classes.backgroundWhite
        )}
      >
        <Grid container={true} className={classes.wrapperContent}>
          <Grid
            container={true}
            className={clsx(
              classes.iconWrapper,
              props.iconGold ? classes.iconGold : classes.iconBlue
            )}
          >
            {props.icon}
          </Grid>

          <Typography
            variant={props.variant || "h4"}
            component={props.component || "h3"}
            className={clsx(
              props.titleBold ? classes.titleBold : undefined,
              props.titleBlack ? classes.titleBlack : undefined,
              props.titleSerif ? classes.titleSerif : undefined
            )}
          >
            <SanitizeHTML html={props.title} hyphenateText={true} />
          </Typography>

          <Typography variant="body1" component="div">
            <SanitizeHTML html={props.description || ""} hyphenateText={true} />
          </Typography>
        </Grid>
      </Card>
    </Grid>
  );
};

export default RVOKeyFactCard;
